import {styled} from 'styled-components'

export const LightSection = styled.div`
padding: 90px;
background: #fff;
color: #5c5c5c;

@media (max-width: 768px) {
   padding: 20px;
  }
`;
