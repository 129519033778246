import { useMemo } from "react"
import { NavigationBar } from "./NavigationBar.styled"
import { NavigationList } from "./NavigationList.styled"
import { NavigationListItem } from "./NavigationListItem.styled"
import logo from '../../images/with-clarity-logo.png';
import { useScreenDetector } from "../../hooks/useScreenDetector";
import { Link } from "react-router-dom";


const menuItems = [
    { name: 'Itinerary', href: "/#itinerary" },
    { name: 'The Boat', href: "/#theboat" },
    { name: 'The Crew', href: "/#thecrew" },
    { name: 'About', href: "/#about" },
    { name: 'The Cost', href: "/#thecost" },
    { name: 'F.A.Q', href: "/#questions" },
    { name: 'When to Travel', href: "/#whentotravel" },
    { name: 'Contact Us', href: "/#contact" },
]
export const Navigation = () => {
    const listItems = useMemo(() => menuItems.map(item => <NavigationListItem><a href={item.href}>{item.name}</a></NavigationListItem>), [])

    const { isTablet } = useScreenDetector();
    return (
        <NavigationBar>
            <div style={{ margin: '30px' }}>
                <Link to="/"><img src={logo} alt="with clarity logo" /></Link>
            </div>
            {!isTablet &&
                <div style={{ marginLeft: '30px', marginRight: '10px' }}>
                    <NavigationList>
                        {listItems}
                    </NavigationList>
                </div>
            }
        </NavigationBar>
    )
}