import styled from "styled-components";

export const NavigationBar = styled.div`
    display: flex;
    background: #619e85;
    color: #fff;
    align-items:center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index:999;
    
`;