import { DarkButton } from "../components/Button/DarkButton";
import { LightSection } from "../components/Section/LightSection";

export const Itinerary = () => (
    <LightSection>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>Itinerary</h1>
        <br/>
        <p>There’s a direct correlation between the number of days you stay and the deeper you sink into relaxation. We recognize that not everyone has the same luxury of time, so we’ve set up trips for as few as three days and up to twelve. Although we recommend at least six days, will work with you to make your travel experience amongst the best you’ll ever have.</p>
        <br />
        <h3>For 3 and 4 day trips, you can expect to see:</h3>
        <p><strong>Other-worldly Komodo Dragons</strong></p>
        <p>Oversized monitor lizards, these carnivorous, other-worldly beings are truly amazing. And they’re endemic to the island of Komodo, which was declared a World Heritage Site by UNESCO about thirty years ago. Since 2021 experts believe they’re headed towards extinction, so Komodo dragons are now considered an endangered species. Easily the largest lizard on earth, Komodo Dragons can grow up to 10 feet, yet no one knows exactly why. Some theorize it’s due to the biological effect known as Island Gigantism: no other carnivorous animals fill the niche on the islands where they live. Others say they’re a relict of prehistoric lizards from Australia, most of which died out after the Pleistocene era, due to human activity. Yet almost everyone agrees: there’s nothing on the planet like them.</p>
        <p><strong>Beaches – stunning, private, and quiet</strong></p>
        <p>Indonesian islands form an intricate coastline that’s the fourth longest in the world. Being so extensive, it’s characterized by coral reefs, deposits from volcanoes, and rich marine biodiversity. The islands we visit make up the driest climate in the country. The combination of effects create a rare form of beach: relatively dry, surrounded with endemic plants and cliffs that create visual privacy. Coral reefs form simple white or yellow sand beaches. Wave action in the waters is largely generated by local winds, which are gentle in the equatorial zone – perfect for swimming and snorkeling.</p>

        <p><strong>Thermo-regulating Flying Foxes</strong></p>
        <p>With a habitat ranging from Africa to Southeast Asia and Australia, these creatures – with faces that look like foxes – are the largest bats on earth. Think: Six foot wingspans… yet that’s not all that keeps them from being average flying bats. Living in large colonies, these “megabats” use sight, rather than echo-location, to navigate. And, they have an excellent sense of smell. It’s phenomenal to see them head out at night, searching for fruit – their dietary staple. They’ve evolved to thermo-regulate, as the trees get really hot at times. Sometimes you can even see them during the day, roosting in large numbers, flapping their wings.</p>

        <p><strong>The Coral Triangle – greatest diversity on earth</strong></p>
        <p>Although it makes up less than 2% of Earth’s oceans, the Coral Triangle encapsulates 75% of all known coral species in the world. There’s seven species of marine turtles on our planet – and six of them are found in the Coral Triangle. Imagine a place with a staggering biodiversity that includes peacefully-gliding manta rays, the world’s largest extent of mangroves, over 3,000 different species of reef fish, and 500 species of reef-building corals. Nothing short of spectacular.</p>

        <br />
        <h3> For 6 and 9 day voyages, we’ll get to all of the above, plus:</h3>
        <p><strong>Namo Island, with its rare red beach</strong></p>
        <p>Get this: there’s less than ten beaches on the entire planet that have red beaches. The pink-red color comes from foraminifera, single-celled organisms. Funny that most people don’t know them; they’ve been on earth for the last 540 million years. It’s the shells of foraminifera, that churn up on only a few select beaches in the world. And it’s a very small number of people on the planet who can actually see them. Join the club.</p>

        <p><strong>The breathtaking, picturesque Padar Island</strong></p>
        <p>Green mountains surrounded by three turquoise bays? Sure, it sounds lovely. But Padar Island has a surreal quality, due to the different colored sand in each one of its bays – one’s white, one’s black, and one’s pink. To see ‘em, we’ll go early, while the sun’s still low. A thirty minute hike up, and you’re in one of the most picturesque places on the planet. Yep – guaranteed.</p>

        <p><strong>Rangko Cave – literally, a tropical wonderland</strong></p>
        <p>Enter a swimming hole inside a cavern. Crystal clear water, that seems to light up electric turquoise when the sunlight enters through the mouth of the cave. Marvel at the vibrating reflections of the water, off the stalactites that seem to drip down from above. Swim, cliff jump, or just hang out in a stalagmite and stalactite-filled tropical wonderland.</p>

        <br />
        <h3>For 12 day trips, you can enter untouched territory:</h3>
        <p><strong>Banta Island</strong></p>
        <p>Banta is an island from a dream, with a jagged coastline, high mountains, finger-shaped bays, and deserted white beaches. There are no villages or formal trails, which only contributes to the already rugged conditions of the island. And yet you can easily climb the foothills for fantastic views of the Sape Strait, as well as the grandiose Komodo Island. On the west side of Banta Island is a breathtaking view of the volcanic island of Sangeang, rising tall from the beautiful blue sea. Explore below the surface of the sea with a snorkel and mask, and you’ll find a magnificent underwater world that’s different from what you’ve already seen – and it’s just waiting to be explored.</p>

        <p><strong>Sangeang Island</strong></p>
        <p>Our boat will float around this impressive volcanic mountain island – which at nearly 2000 meters is actually visible from Labuan Bajo, where you started your trip. An active complex volcano, we can go by dinghy to the land, to see the magma trace of the eruption. This volcano is still active, so of course we’ll make sure it’s safe before we actually approach it. In fact, Sangeang Api is one of the most active volcanoes in the Lesser Sunda Islands. It erupted in 1988 and at that time, the island’s inhabitants were evacuated. Between its first recorded eruption in 1512 and it’s last, it has erupted 17 times – most recently in December of 2012.</p>
        <p><strong>Tanjung Mariam</strong></p>
        <p>Besides boasting beautiful beaches, Tanjung Mariam is famous for its columnar joint rocks, which look like they were created by some ancient civilization. But in fact, it’s just nature doing something spectacular again, forming a regular array of polygonal prisms with igneous rocks, cooling lava flows, and ash flow tuffs. We’ll have a unique chance to see these natural sculptural formations in stone.</p>
        <p><strong>Pulau Kelapa</strong></p>
        <p>The uninhabited Island Pulau Kelapa literally means “Coconut Island”, although you won’t find any coconuts there. An outer island of West Nusa Tenggara Province, it’s known for the magnificent view from the top of the island. Pulau Kelapa is permanently uninhabited, but some coastal plains are tilled by seasonal farmers nearby. Trek to the peak of the island for an extraordinary 360 degree view: in every direction, the eye can see vast expanses of fields and savannah-like hills. Prefer to stay closer to the pinisi? The small rocky islands around Pulau Kelapa create shelter from waves, and offer an unforgettable snorkelling experience.</p>

        <br />
        <br />
        <DarkButton href="/">Return to home</DarkButton>
    </LightSection>
)