import { useMemo } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';


const tripDetails = [
    { question: 'WHERE IS FLORES, EXACTLY?', answer: <p>Flores is in the country of Indonesia. Indonesia is an amazing nation, boasting about 17,000 islands. You’ve probably heard of some – like Bali, Sumatra, and Java. The island of Flores is 700 kilometers from Bali.</p> },
    {
        question: 'HOW MUCH TIME DOES IT TAKE TO GET TO LABUAN BAJO, ON THE ISLAND OF FLORES (THE DEPARTURE POINT FOR THE SHIP)?', answer: <p>Of course, that depends upon where your travel is originating. A range of flight times (including transits) are listed below, if flying into Bali:<br />
            Western Europe,15 – 18 hours<br />
            USA, 21 – 27 hours<br />
            East Asia, 2 – 5 hours<br />
            Australia, 3 – 6 hours<br />
            From Bali (airport code DPS), Labuan Bajo (airport code LBJ) is an additional 45 minute plane ride to the east.</p>
    },
    { question: 'HOW DO I GET TO THE ISLAND OF FLORES?', answer: <><p>There are many ways to fly in to the country of Indonesia, for example through islands like Java, Sumatra, Sulawesi, or Bali. We recommend Bali for several reasons: it’s very proficient in handling travelers arriving from all over the world, it’s got great proximity to the island of Flores, and it has several daily flight departures between the two islands. Moreover, a lot of people like to spend some time in Bali, too – see “WHAT ABOUT ADD-ON EXTENSIONS?” under Booking Logistics. A roundtrip flight from Bali to Flores is fairly cheap.</p><p>Once you land in Flores, we’ll meet you at the airport and transfer you directly to your hotel, one day before the ship sails.</p><p>The next day, you can either go to the dock in Labuan Bajo and meet us there, at the boat. Or, let us pick you up from your hotel – your choice.</p><p>We request that all travelers come in the day before our departure, and stay in a Labuan Bajo hotel. Recommendations for hotels are sent in our pre-departure materials.</p></> },
    { question: 'WHAT ARE THE MEALS LIKE?', answer: <ul><li>We offer a mix of local dishes and Western meals. Local dishes are mostly noodle and rice-based, with vegetables and meat or fish. It’s a good opportunity to try new foods and new flavors – think coconut milk, tofu and tempe, peanut sauces, and even hot chilis, if you desire. Western foods are European or American-based. We provide three meals a day, and there’s always enough food for everyone. Plus, you can expect fresh Juice-of-the-Day, and island fruits available, all the time. Also coffee, tea, and water. Forget about junk food: imagine eating healthy, delicious, fresh food, for the entire time you’re on the boat!</li><li>Don’t forget that we also include alcoholic drinks, so you may enjoy beer, wine, or cocktails as you like.</li><li>While we can’t cater to specific diets, should you have certain food preferences or needs, we’re happy to try and accommodate such.</li></ul> },
    { question: 'HOW MUCH MONEY WILL I NEED ON THE TRIP?', answer: <><ul><li>Not much. We’ve aimed to make things all-inclusive on the boat.</li><li>You’ll come in one day before the ship sails. Most hotels in town will range from US$40 – $80 for the night.</li><li>If you want to pick up a souvenir (like a carved Komodo dragon sculpture), or buy various sundries in the town of Labuan Bajo on your first night pre-sail, you should budget for that. Most people would have a hard time spending more than $30 or $40 for such, but obviously there are more expensive works of art that can be had, too.</li><li>The only other thing to anticipate as a cost is tips. This single act is a most appropriate and personal way to show your thanks to the local men working on the ship. The crew work hard to make sure you have the best possible experience, and tipping is most appreciated by them.</li></ul><br /><p><i>Please note:  Giving tips in the local currency is preferred, so the crew can avoid changing money and getting less-than-desirable exchange rates.</i></p><p><i>Suggested range of tips per day, for the whole crew might range from USD$40 – US$80 for EACH DAY of your trip. In Indonesian rupiah, that converts to about IDR 600,000 to IDR 1,150,000 for EACH DAY.</i></p></> },
]

const accommodation = [
    { question: 'WHAT ARE THE CABINS LIKE?', answer: <><ul><li>1 – Upper Deck Cabin with 1 double bed (can have an additional single mattress on floor)</li><li>2 – Lower Deck Cabins with 1 double bed and 1 single bed</li><li>2 – Lower Deck Cabins with 2 single beds</li></ul></> },
    {
        question: 'WHAT ARE THE TOILET FACILITIES LIKE?', answer: <p>All cabins have private bathrooms equipped with toilets, sinks, and hot water showers.</p>
    },
    { question: 'WILL THERE BE CELL RECEPTION AND INTERNET ACCESS?', answer: <ul><li>Cell reception varies like on any body of water. Overall, the country has a very strong telecommunications network. You may want to arrange an international calling plan that best suits your needs, with your cell phone provider before you leave. Alternatively, you can buy a SIM card in Indonesia (they’re cheap and easy to obtain), and use a local number for calls.</li><li>The boat is equipped with Wifi. Although internet access can occasionally go down in this part of the world, it usually comes back up rather quickly. Having said that, there is one particularly isolated spot on our trips where we will lose reception for about a day.</li></ul> },
]


const booking = [
    {
        question: 'HOW DO I MAKE A RESERVATION?', answer: <><p>Contact us using the tab above. Be sure to include the following information:</p><ul><li>What months you’d like to travel (we run trips from April to November)</li><li>What length trip you’d like (3, 4, 6, 9, or 12 days)</li><li>The number of travelers (1 to 13 persons)</li></ul><p>We’ll get back to you as soon as possible. Please remember that we may be in a different time zone than you – even on the other side of the world!</p><p><i>A Note to Travel Agents and Groups:<br />
            We work with agencies on a commission basis, and we offer very favorable rates for groups. Please don’t hesitate to contact us at TravelWithClarity@gmail.com for more information.</i></p></>
    },
    {
        question: 'CAN I EXTEND MY TRIP?', answer: <><p>Definitely. We can suggest a few add-on experiences that are our personal favorites:</p><ul><li>Spend time in Bali, either before or after the sailing trip. Length and activities can be tailored to your desire. People commonly spend anywhere from a few days to three weeks on the Island of the Gods. Activities can include yoga, surfing, learning about the arts and culture, or delving into the spiritual elements offered on the island.</li><li>Scuba diving excursions are available from the islands of Flores, Lombok, or Bali. We can help arrange various lengths and locales, so please talk to us about these.</li><li>We can arrange a local guide and/or driver for you.</li></ul></>
    },
    { question: 'WHAT TRAVEL DOCUMENTS DO I NEED?', answer: <><p>For most people, the answer to this is simple: just a valid passport and a valid return airline ticket. But read these details, please:</p><ul><li>When coming into the country, Immigration will grant you a free visa, good for a 30-day stay. Most travelers are eligible for an additional 30-day stay, by requesting a “Visa on Arrival” while still in the airport. Although this option exists for nearly 90% of the countries in the world, there are a few countries with which Indonesia restricts travel, and so a visa arranged beforehand would then be necessary. Because of that, you’ll want to confirm your specific situation with a visa agent or embassy in your home country, beforehand.</li><li>When on the plane, a flight attendant will give you a customs form to fill out, which you’ll turn in as you exit the airport on arrival. It will ask you to list an address for where you’ll be staying, and you should list the hotel you’ll be in on your first night.</li></ul></> },
    { question: 'WHAT’S YOUR CANCELLATION POLICY?', answer: <><p>Cancellation requests must be sent via email, to TravelWithClarity@gmail.com. The date of your email determines the cancellation fee:</p><ul><li>241 days or more before departure: 100% of trip cost returned, minus $1,000 deposit.</li><li>151-240 days before departure: 60% of trip cost returned.</li><li>61-150 days before departure: 30% of trip cost returned.</li><li>60 days or less before departure: 0% of trip cost returned.</li></ul></> },
    { question: 'IS IT SAFE TO GIVE MY CREDIT CARD DETAILS?', answer: <><p>Yes. Once we’ve confirmed your desired dates, we’ll take your payment via our payment processor. We use an online payment platform called “Stripe”. Considered the number one credit card payment processor, it is the new standard in online payments. Stripe handles billions of dollars every year for businesses around the world.</p><p>To ensure that your credit cards and personal information are kept as secure as possible, Stripe uses up-to-date Secure Sockets Layer technology (SSL). This is the most secure method of transferring sensitive data over the Internet.</p></> },
]

const personalConcerns = [
    { question: 'AM I EXPECTED TO HELP WITH THE SAILING?', answer: <p>Actually, we don’t expect you to do anything. If you want to recline in the sun, or chat with the other people, or keep your eyes peeled for Komodo dragons or flying fish or other wildlife – that’s totally up to you. Most travelers won’t be at all interested in sailing, and that’s fine. Bottom line: it’s your own unique adventure; we want you to choose whatever you want to do. However, if you are interested in sailing, our crew will be happy to integrate you in the ways of anchoring, tying a knot, hoisting the sails, or anything else nautical.</p> },
    { question: 'WILL I GET SEASICK?', answer: <><p>While we can’t give you a 100% “no” on this, it’s quite rare for a person to get seasick in this part of the world, on this sort of vessel. Partly due to the size and structure of the boat, and mostly due to the calm waters. We sail around a collection of scattered islands. They create a natural barrier that breaks down the rough waters from the Indian Ocean in the south and the Flores Sea in the north. The result is that we’re moving in relatively tranquil waters.</p><p>Having said that, it’s been theorized that seasickness can even come from… worrying about seasickness. So if it’s a concern of yours now, it might be wise to bring some motion sickness medicine with you. It’s small enough to carry along, and you’ll have peace of mind. You’ll probably end up bringing it home with you – which would actually be a good thing, right?</p></> },
    { question: 'WHAT DO I NEED TO BRING?', answer: <><p>Bring comfortable shoes for walking or trekking, if you’d like to explore any of the islands on foot. Waterproof, reef-friendly sunscreen. A hat, and sunglasses, if you like. Payment on the islands is mostly on a cash basis – so make sure you bring enough cash in Rupiah – the local currency (See HOW MUCH MONEY WILL I NEED ON THE TRIP, in FAQ section TRIP DETAILS.) Overall, pack lightly, and bring lightweight clothing. Soft or hard luggage is fine; we have storage space under most beds for such.</p><p>We’ll send out a detailed suggested packing list once your trip is booked.</p></> },
    { question: 'HOW DO YOU HANDLE MY PERSONAL INFORMATION?', answer: <p>We will not, in any circumstances, share your personal information with other individuals or organizations without your permission, including public organizations, corporations or individuals, except when applicable by law. This pertains to any data that you’ve transmitted to us electronically, and any verbal information that you’ve shared with us. And of course, before ever using images in promotional materials, we’ll seek your permission – and honor your decision.</p> },
    { question: 'DO I NEED ANY VACCINES? IS IT EASY TO GET MEDICATION THERE?', answer: <><p>No vaccines are necessary to enter the country, but we recommend following the guidelines for travel from the US-based organization, Centers for Disease Control and Prevention.</p><p>If you require regular medication, be sure to have enough of it before your trip departs, as all medicine may not be available.</p></> }
]

const data: Record<string, any[]> = {
    tripDetails: tripDetails,
    accommodation: accommodation,
    booking: booking,
    personalConcerns: personalConcerns
}


export const FaqAccordion = ({ type }: { type: string }) => {
    const items = data[type];

    const accordionItems = useMemo(() => items.map(item => (
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton style={{ width: 'unset' }}>
                    {item.question}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                {item.answer}
            </AccordionItemPanel>
        </AccordionItem>
    )), [items]);

    return (
        <Accordion>
            {accordionItems}
        </Accordion>
    )
}