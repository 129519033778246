import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Home } from './pages/home';
import { Navigation } from './components/Navigation';
import { Footer } from './components/Footer';
import { Itinerary } from './pages/itinerary';
import { TheBoat } from './pages/theboat';
import { Faq } from './pages/faq';

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/itinerary" element={<Itinerary />} />
          <Route path="/the-boat" element={<TheBoat />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
