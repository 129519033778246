import { DarkSection } from "../../components/Section/DarkSection";

import Itinerary1 from '../../images/itinerary/itinerary-1.jpeg'
import Itinerary2 from '../../images/itinerary/itinerary-2.jpeg'
import Itinerary3 from '../../images/itinerary/itinerary-3.jpg'
import Itinerary4 from '../../images/itinerary/itinerary-4.jpeg'
import Itinerary5 from '../../images/itinerary/itinerary-5.jpeg'
import Itinerary6 from '../../images/itinerary/itinerary-6.jpeg'
import Itinerary7 from '../../images/itinerary/itinerary-7.jpg'
import Itinerary8 from '../../images/itinerary/itinerary-8.jpg'
import Itinerary9 from '../../images/itinerary/itinerary-9.jpg'
import Itinerary10 from '../../images/itinerary/itinerary-10.jpeg'
import Itinerary11 from '../../images/itinerary/itinerary-11.png'
import Itinerary12 from '../../images/itinerary/itinerary-12.jpeg'
import Itinerary13 from '../../images/itinerary/itinerary-13.jpeg'
import Itinerary14 from '../../images/itinerary/itinerary-14.jpeg'
import Itinerary15 from '../../images/itinerary/itinerary-15.jpeg'
import { ImageGrid } from "../../components/ImageGrid";
import { LightButton } from "../../components/Button/LightButton";

const images = [Itinerary1, Itinerary2, Itinerary3, Itinerary4, Itinerary5, Itinerary6, Itinerary7, Itinerary8, Itinerary9, Itinerary10, Itinerary11, Itinerary12, Itinerary13, Itinerary14, Itinerary15];


export const Itinerary = () => (
    <DarkSection id="itinerary">
        <h2>Itinerary</h2>
        <br />
            <hr style={{maxWidth: '40%'}}/>
            <br />
        <p>Amongst literally thousands of islands – Indonesia’s archipelago boasts nearly 17,000 pieces of land surrounded by water – many are uninhabited, and many are small. Glancing around, you can see craggy rocks, sensuous hills, and mountains – all surrounded by a mix of seas. The sparkling sun reflects off them, and wildlife appears at the strangest times…</p>
        <h3>Think flying fish, flying foxes, and bright blue kingfishers – flying fast from the trees.</h3>
        <p>As striking as it is wild, the land and the sea and the air and the light all combine to create a spectacular landscape.</p>
        <p><i>Our exact itineraries amongst the Komodo Islands vary on length of trip…</i></p>
        <br />
        <br />
        <LightButton href="/itinerary">Read More</LightButton>
        <br />
        <br />
        <br />
        <br />
        <br />
        <ImageGrid images={images} alt="itinerary hot spots" />
        <br />
        <p><i>Want to explore other parts of Indonesia? We have itineraries for the south Sulawesi islands, as well as the Raja Ampat region…</i></p>
        <br />
        <LightButton href="#contact">Contact Us</LightButton>

    </DarkSection>
)