import { DarkButton } from "../../components/Button/DarkButton";
import { LightSection } from "../../components/Section/LightSection";

export const Questions = () => (
    <LightSection id="questions">
        <h2>Questions?</h2>
        <br/>
        <br />
        <DarkButton href='/faq'>See our F.A.Q</DarkButton>
        <br/>
        <br/>
        {/* See our faq button */}
    </LightSection>
)