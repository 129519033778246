import styled from "styled-components";

export const StyledImage = styled.img`
    padding: 10px;
    width: 100%; 
    max-width: 400px;

    @media (max-width: 768px) {
        max-width: 300px;
    }

    @media (max-width: 400px) {
        max-width: 200px;
    }

`;