import { DarkSection } from "../../components/Section/DarkSection";
import { useScreenDetector } from "../../hooks/useScreenDetector";

import crew1 from '../../images/thecrew/thecrew-1.jpeg'
import crew2 from '../../images/thecrew/thecrew-2.jpeg'
import { StyledImage } from "./Image.styled";

export const TheCrew = () => {

    const { isMobile } = useScreenDetector();

    return (
        <DarkSection id="thecrew">
            <h2>The Crew</h2>
            <br/>
            <hr style={{maxWidth: '40%'}}/>
            <br/>
            <p>The crew on our Pinisi are ideal. They are friendly, low-key, professional, and most important, good at what they do. All seven of them go with us on each excursion. These guys work well together as one team, and each one is skilled at taking over the jobs of others. It’s nothing short of astounding to see the efficiency amongst this group of men.</p>
            <p>The crew know these waters well. And by the end of the trip, you’ll know the crew well – so you can anticipate having new friends in this part of the world.</p>
            {/* images */}
            <center>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                    <StyledImage src={crew1} alt="group of crew members" />
                    <StyledImage src={crew2} alt="group of crew members" />
                </div>
            </center>
        </DarkSection>
    )
}