import { CaretLeft, CaretRight, X } from "phosphor-react";
import { useScreenDetector } from "../../hooks/useScreenDetector";

export const ImageModal = ({image, index, setIndex, length}: any) => {
    const {isMobile} = useScreenDetector();
console.log(length);
    return (
    <div style={{width: '100vw', height: '100vh', position: 'fixed', left: 0, top: 0, overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.7)', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{position: 'fixed', top: '50px', right: '50px'}} onClick={() => setIndex(-1)}><X size={32} color="white"  /></div>
        <div style={{position: 'fixed', top: isMobile ? '75%' : '45%', left: '50px'}} onClick={() => index===0 ? setIndex(length-1) : setIndex(index-1)}>
            <CaretLeft size={32} color="white"  />
        </div>
        <img src={image} style={{maxWidth: '70%', maxHeight: '80%'}}alt="ambiance" />
        <div style={{position: 'fixed', top: isMobile ? '75%' : '45%', right: '50px'}} onClick={() => index=== (length-1) ? setIndex(0) : setIndex(index+1)}><CaretRight size={32} color="white"  /></div>

    </div>
)}