import { LightSection } from "../../components/Section/LightSection";

export const About = () => (
    <LightSection id="about">
        <h2 style={{ color: '#619e85' }}>About</h2>
        <br/>
        <hr style={{maxWidth: '40%', opacity: '0.5'}}/>
        <br/>
        <p>With Clarity aims to give travelers new views as they journey. The idea was conceived after decades of traveling the world in different styles, realizing that the best trips come out of small scale experiences. The company opened its doors in 2019, and has now grown into a multi-cultural team comprised of Americans, Europeans, and Asians – all based on the island of Bali.</p>
        <p>Although the staff have different pursuits, they share a common denominator: a passion for travel, and a fascination with the great outdoors. They’re not afraid to be different, and to create experiences for travelers that offer new perspectives of the planet.</p>
        <p>With Clarity believes relaxation is an essential component of travel, and that physical comforts, mental stimulation, visual clarity, and interesting tastes can all combine for a superlative journey. That’s why their mission is to create the sort of travel many dream of: full of unique things, beauty, and comfort – all on a charming vessel led by a skilled captain and crew.</p>
    </LightSection>
)