import { DarkSection } from "../../components/Section/DarkSection";

export const WhenToTravel = () => (
    <DarkSection id="whentotravel">
        <h2>When to Travel</h2>
        <br/>
        <hr style={{maxWidth: '40%'}}/>
        <br/>
        <p>Sailing amongst islands that change at any given point of the year, you’ll see nature in progress. From April and May and June, the islands are a lush green. July through September see a more dry and barren – yet equally stunning – savannah-covered vista, contrasting nicely with the water. Later in October the green returns in striking patches, and from then until March, nature works hard creating new life again. Expect year-round temperatures in the 80s, and turquoise-blue water that never feels cold.</p>
        <p>We run our trips during the safest and most optimal time to be here – from April to the first week in November. For date availability, please contact us at</p>
        <h3>TravelWithClarity@gmail.com</h3>
    </DarkSection>
)