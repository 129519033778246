import styled from "styled-components";

export const LightButton = styled.a`
    background :#77b49b;
    color: #fff;
    padding: 12px 15px;
    font-size: 20px;
    text-decoration:none;
    cursor: pointer;

    transition: 0.2s background;

    &:hover, &:active, &:focus {
        background: #fff;
        color: #77b49b;
    }
`;