import { DarkSection } from "../../components/Section/DarkSection";
import padar from '../../images/padar.jpeg';
import { StyledImage } from "./Image.styled";

export const Hero = () => (
    <>
        <div style={{ width: '100vw', maxHeight: '900px' }}>
            <StyledImage src={padar} alt="A tropical island with many bays" />
        </div>
        <DarkSection style={{ marginTop: '-20px' }}>
            <h1>Traditional <strong>Sailing</strong> Seen in a <strong>New Way</strong></h1>
            <br />
            <hr style={{maxWidth: '40%'}}/>
            <br />
            <p>Imagine sailing on a traditional wooden vessel through the Flores Sea. True relaxation, gorgeous views. A once-in-a-lifetime opportunity, this is. A dream that can be fulfilled. Let us show you this breathtaking part of the world. Come experience it… and travel With Clarity.</p>
        </DarkSection>
    </>
)