import styled from "styled-components";

export const DarkButton = styled.a`
    background :#77b49b;
    margin: 20px;
    color: #fff;
    padding: 12px 15px;
    font-size: 20px;
    text-decoration:none;
    cursor: pointer;

    transition: 0.2s background;

    &:hover, &:active, &:focus {
        background: #eae9e9;
        color: #77b49b;
    }
`;