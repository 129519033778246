import { LightSection } from "../../components/Section/LightSection";

export const Contact = () => (
    <LightSection id="contact">
        <h2>Contact With Clarity</h2>
        <br/>
        <hr style={{maxWidth: '40%', opacity: '0.5'}}/>
        <br/>
        <p>To contact us send an email to </p>
        <h3><strong>TravelWithClarity@gmail.com</strong></h3>
    </LightSection>
)