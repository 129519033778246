import styled from "styled-components";

export const StyledImage = styled.img`
    width: 300px;
    height: 200px;

    @media (max-width: 768px) {
    width: 150px;
    height: 100px;
  }
  @media (max-width: 400px) {
    width: 200px;
    height: 133px;
}
`;