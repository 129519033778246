import { CostTable } from "../../components/CostTable";
import { DarkSection } from "../../components/Section/DarkSection";

export const TheCost = () => (
    <DarkSection id="thecost">
        <h2>The Cost</h2>
        <br/>
        <hr style={{maxWidth: '40%'}}/>
        <br/>
        <p style={{ textAlign: 'left' }}>Covered</p>
        <ul style={{ textAlign: 'left' }}>
            <li>
                Three meals per day on boat
            </li>
            <li>
                Soft drinks, mineral water, coffee, tea
            </li>
            <li>
                Freshly squeezed Juice-of-the-Day
            </li>
            <li>
                Beer, wine, arak, vodka, gin, whisky, and rum
            </li>
            <li>
                Snacks
            </li>
            <li>
                Fruits – any time of day or night
            </li>
            <li>
                All linens, including towels
            </li>
            <li>Services of Captain and Crew</li>
            <li>
                National Park access fees
            </li>
            <li>Wifi</li>
            <li>
                Snorkeling gear
            </li>
            <li>Life jackets</li>
            <li>
                Pick up from your hotel to boat marina on first day
            </li>
        </ul>
        <br />
        <CostTable />
        <p style={{ textAlign: 'left' }}>NOTES</p>
        <ul style={{ textAlign: 'left' }}>
            <li>
                Prices effective from January 1, 2024
            </li>
            <li>
                All Prices in USD
            </li>

        </ul>

        <p style={{ textAlign: 'left' }}>Not Covered</p>
        <ul style={{ textAlign: 'left' }}>
            <li>
                Accommodations for one night, before we sail.
            </li>
            <li>
                Tips, if you choose to give gratuity to the crew.
            </li>

        </ul>

        <p style={{ textAlign: 'left' }}>Optional</p>
        <ul style={{ textAlign: 'left' }}>
            <li>
                Services of one or two Group Leaders, if desired (Depends on group size. Leaders can be Western, Indonesian, or both)
            </li>
            <li>
                Group Leaders can provide warm welcome with transport from airport in Labuan Bajo, Flores (LBJ) to your hotel, on the day before we sail, snorkeling instruction, and explanations and general assistance throughout the trip.
            </li>
            <li style={{ listStyle: 'none', paddingLeft: '30px' }}>
                One Western Group Leader accompaniment, 3,4 or 6 day trips: $1,400
            </li>
            <li style={{ listStyle: 'none', paddingLeft: '30px' }}>
                One Western Group Leader accompaniment, 9 or 12 day trips: $1,800
            </li>
            <li style={{ listStyle: 'none', paddingLeft: '30px' }}>
                Two Western Group Leaders accompaniment, 3,4 or 6 day trips: $1,900
            </li>
            <li style={{ listStyle: 'none', paddingLeft: '30px' }}>
                Two Western Group Leaders accompaniment, 9 or 12 day trips: $2,300
            </li>
            <li>
                All itineraries are available as dive trips, with full gear and divemaster. Diving, per person / $220 per day (includes dive gear and dive master)
            </li>
        </ul>
    </DarkSection>
)