import { useState } from "react";
import { Grid } from "./Grid.styled";
import { StyledImage } from "./Image.styled";
import { ImageModal } from "./ImageModal";

export const ImageGrid = ({ images, alt }: { images: string[], alt: string }) => {
    const [index, setIndex] = useState(-1);

    const length = images.length;
    return (
        <>
        {index >= 0 ?  <ImageModal image={images[index]} index={index} setIndex={setIndex} length={length} />: <Grid>
            {images.map((image, i) => <center><StyledImage src={image} alt={alt} onClick={() => setIndex(i)}/></center>)}
        </Grid>}
        </>
)}