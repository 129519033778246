import styled from "styled-components";

export const StyledImage = styled.img`
    width: 100vw;
    height: auto;
    margin-top: -150px;

    @media (max-width: 1040px) {
        margin-top: 0px;

   }

   @media (max-width: 768px) {
        margin-top: 50px;

   }
 `;