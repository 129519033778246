import { FaqAccordion } from "../components/Accordion/FaqAccordion/FaqAccordion";
import { DarkButton } from "../components/Button/DarkButton";
import { LightSection } from "../components/Section/LightSection";



export const Faq = () => {



    return (
        <LightSection>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>F.A.Q</h1>
            <br />
            <div style={{ textAlign: 'left' }}>
                <h3>Trip Details</h3>
                <FaqAccordion type="tripDetails" />
                <br />
                <h3>Accommodation</h3>
                <FaqAccordion type="accommodation" />
                <br />
                <h3>Booking</h3>
                <FaqAccordion type="booking" />
                <br />
                <h3>Personal Concerns</h3>
                <FaqAccordion type="personalConcerns" />
                <br />
            </div>
            <br />
            <p>HAVE A QUESTION NOT LISTED ABOVE?</p>
            <p>Just email us at <strong>TravelWithClarity@gmail.com</strong></p>
            <br />
            <br />
            <DarkButton href="/">Return to home</DarkButton>
        </LightSection>
    )
}