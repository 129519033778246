import { About } from "../sections/About";
import { Ambiance } from "../sections/Ambiance";
import { Contact } from "../sections/Contact";
import { Hero } from "../sections/Hero";
import { Itinerary } from "../sections/Itinerary";
import { Questions } from "../sections/Questions";
import { TheBoat } from "../sections/TheBoat";
import { TheCost } from "../sections/TheCost";
import { TheCrew } from "../sections/TheCrew";
import { WhenToTravel } from "../sections/WhenToTravel";

export const Home = () => (
    <>
        <Hero />
        <Ambiance />
        <Itinerary />
        <TheBoat />
        <TheCrew />
        <About />
        <TheCost />
        <Questions />
        <WhenToTravel />
        <Contact />
    </>
)