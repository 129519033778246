import { DarkSection } from "../Section/DarkSection";

export const Footer = () => (
    <DarkSection>
        <span>PT THE INVISIBLE WORLD</span><br /><br />
        <span>
            Biliq Sunset Office Space • Sunset Road 819 • Badung, Bali, 80361 • Indonesia
        </span><br /><br />
        <span>
            Copyright 2022 With Clarity | All Rights Reserved
        </span>
    </DarkSection>
)