import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

export const CostTable = () => (
    <Table style={{ borderCollapse: 'collapse' }}>
        <Thead >
            <Tr>
                <Th style={{ border: '1px solid white', padding: '10px' }}>Number of Travelers</Th>
                <Th style={{ border: '1px solid white', padding: '10px' }}>3 Days</Th>
                <Th style={{ border: '1px solid white', padding: '10px' }}>4 Days</Th>
                <Th style={{ border: '1px solid white', padding: '10px' }}>6 Days</Th>
                <Th style={{ border: '1px solid white', padding: '10px' }}>9 Days</Th>
                <Th style={{ border: '1px solid white', padding: '10px' }}>12 Days</Th>
            </Tr>
        </Thead>
        <Tbody>
            <Tr>
                <Td style={{ border: '1px solid white', padding: '10px' }}>1-4</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$9,495</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$11 ,995</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$18,995</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$28,495</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$33,395</Td>
            </Tr>
            <Tr>
                <Td style={{ border: '1px solid white', padding: '10px' }}>5-8</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$11 ,095</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$18,995</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$20,995</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$30,895</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$36,195</Td>
            </Tr>
            <Tr>
                <Td style={{ border: '1px solid white', padding: '10px' }} >9-12</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$12,695</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$15,595</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$22,995</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$33,295</Td>
                <Td style={{ border: '1px solid white', padding: '10px' }}>$38,995</Td>
            </Tr>
        </Tbody>
    </Table>
)