import { LightSection } from "../../components/Section/LightSection";

import theboat1 from '../../images/theboat/theboat-1.jpg'
import theboat2 from '../../images/theboat/theboat-2.jpg'
import theboat3 from '../../images/theboat/theboat-3.jpg'
import theboat4 from '../../images/theboat/theboat-4.jpg'
import theboat5 from '../../images/theboat/theboat-5.jpeg'
import theboat6 from '../../images/theboat/theboat-6.jpeg'
import theboat7 from '../../images/theboat/theboat-7.jpg'
import theboat8 from '../../images/theboat/theboat-8.jpg'
import theboat9 from '../../images/theboat/theboat-9.jpg'
import theboat10 from '../../images/theboat/theboat-10.jpg'
import theboat11 from '../../images/theboat/theboat-11.jpg'
import theboat12 from '../../images/theboat/theboat-12.jpg'
import { ImageGrid } from "../../components/ImageGrid";
import { DarkButton } from "../../components/Button/DarkButton";

const images = [theboat1, theboat2, theboat3, theboat4, theboat5, theboat6, theboat7, theboat8, theboat9, theboat10, theboat11, theboat12];

export const TheBoat = () => (
    <LightSection id="theboat">
        <h2 style={{ color: '#619e85' }}>The Boat</h2>
        <br/>
        <hr style={{maxWidth: '40%', opacity: '0.5'}}/>
        <br/>
        <p>We sail on a “Pinisi” boat – a large wooden sailing ship, made completely without nails, in the traditional Bugis style. Pinisi have been made this way for at least 1,500 years, by craftsmen on the southern part of the Indonesian island of Sulawesi. In 2017, UNESCO designated these ships as an art of “Intangible Cultural Heritage.”</p>
        <h3>They last forever, because they’re made so well.</h3>
        <p>Find one that’s taking care of, and it’s a gem…</p>
        <br />
        <DarkButton href='/the-boat'>Read more</DarkButton>
        <br />
        <br />
        <br />
        <br />
        <ImageGrid images={images} alt="Scenes from the boat" />
    </LightSection>
)