import { DarkButton } from "../components/Button/DarkButton";
import { LightSection } from "../components/Section/LightSection";

export const TheBoat = () => (
    <LightSection>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>The Boat</h1>
        <br />
        <hr style={{maxWidth: '40%', opacity: '0.5'}}/>
        <br />
        <p>This vessel was built in 2018, and can carry up to 13 guests. The ship has preserved its traditional ambience, but offers modern, clean and safe spaces.</p>
        <p>There are five cabins: one above deck and four below. Additional space for socializing includes three decks on three different levels, and an indoor lounge. All cabins are air conditioned and have their own bathrooms.</p>
        <br />
        <div style={{ textAlign: 'left' }}>
            <h3>Ship Overview</h3>
            <ul>
                <li>Five air conditioned sleeping cabins</li>
                <li>Quality bedsheets, blankets, and towels</li>
                <li>Four expansive decks equipped with sun loungers, beanbags, and wooden chairs</li>
                <li>One dining area</li>
                <li>A warm-wood, expansive salon equipped with built-in seating areas</li>
            </ul>

            <h3>Cabin Details</h3>
            <ul>
                <li>1 – Upper Deck Cabin with 1 double bed (can have an additional single mattress on floor)</li>
                <li>2 – Lower Deck Cabins with 1 double bed and 1 single bed</li>
                <li>2 – Lower Deck Cabins with 2 single beds</li>
            </ul>

            <h3>Ship Particulars</h3>
            <p>
                Built: 2018<br />
                Flag: Indonesia<br />
                Licensed and certified: Labuan Bajo, Flores<br />
                Capacity: 13 passengers<br />
                Crew: 7 men<br />
                Group Leaders, if desired: 1 – 2 people (Western, Indonesian, or combination)<br />
                Cabins: 5<br />
                Tonnage: 77 tons<br />
                Length: 30 meters<br />
                Beam: 6 meters<br />
                Top Speed: 7 knots<br />
                Decks: 3<br />
                Passenger Social Spaces: 5<br />
            </p>
            <ul>
                <li>Monohull one-masted sailing ship with a ketch rig</li>
                <li>230 hp Mitsubishi 6D16 diesel engine</li>
                <li>6,000 liter water tank</li>
                <li>3,000 liter diesel tank</li>
                <li>3 generators</li>
                <li>Swimming ladder</li>
                <li>Life jackets</li>
                <li>2 dinghies</li>
                <li>Garmin Chartplotter</li>
                <li>VHF Radio</li>
                <li>Fire extinguishers</li>
                <li>Fishing lines</li>
                <li>Snorkeling gear</li>
                <li>Dive tanks and equipment</li>
                <li>220 Volt European sockets</li>
                <li>Bluetooth speaker Sony XB60</li>
                <li>WiFi</li>
                <li>Air conditioning</li>
            </ul>

        </div>
        <br />
        <br />
        <DarkButton href="/">Return to home</DarkButton>
    </LightSection>
)