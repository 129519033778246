import {styled} from 'styled-components'

export const DarkSection = styled.div`
padding: 90px;
background: #619e85;
color: #fff;

@media (max-width: 768px) {
   padding: 20px;
  }
`;
