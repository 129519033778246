import { ImageGrid } from "../../components/ImageGrid";
import { LightSection } from "../../components/Section/LightSection";


import Ambiance1 from '../../images/ambiance/ambiance-1.jpeg'
import Ambiance2 from '../../images/ambiance/ambiance-2.jpeg'
import Ambiance3 from '../../images/ambiance/ambiance-3.jpeg'
import Ambiance4 from '../../images/ambiance/ambiance-4.jpeg'
import Ambiance5 from '../../images/ambiance/ambiance-5.jpeg'
import Ambiance6 from '../../images/ambiance/ambiance-6.jpeg'
import Ambiance7 from '../../images/ambiance/ambiance-7.jpeg'
import Ambiance8 from '../../images/ambiance/ambiance-8.jpg'
import Ambiance9 from '../../images/ambiance/ambiance-9.jpg'
import Ambiance10 from '../../images/ambiance/ambiance-10.jpeg'
import Ambiance11 from '../../images/ambiance/ambiance-11.jpeg'
import Ambiance12 from '../../images/ambiance/ambiance-12.jpeg'

const images: string[] = [Ambiance1, Ambiance2, Ambiance3, Ambiance4, Ambiance5, Ambiance6, Ambiance7, Ambiance8, Ambiance9, Ambiance10, Ambiance11, Ambiance12];


export const Ambiance = () => (
    <LightSection id="ambiance">
        <h2 style={{ color: '#619e85' }}>Ambiance</h2>
        <br/>
        <hr style={{maxWidth: '40%', opacity: '0.5'}}/>
        <br />
        <br />
        <p>The mood’s relaxed. And the crew’s adept but mellow, in an island-fashion typical in this part of the world – replete with an overlay of Southeast Asian politeness.</p>
        <h3>This isn’t just the way to travel. This is the way to be.</h3>
        <p>Don’t miss the orange globe go down, and the sky miraculously change, literally second by second. Everything happens slower here in the islands… even the sky colors last long after the sun’s dropped over the horizon.</p>
        <h3>These are, you realize, the sunsets of your life.</h3>
        <p>And without judgement or too much analysis, the vibe’s free and relaxed in a way we just can’t seem to get on land.</p>
        <br />
        <ImageGrid images={images} alt='a beautiful scene showcasing the ambiance' />
    </LightSection>
)